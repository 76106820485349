var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('Loader',{attrs:{"type":"calendar"}}):(!_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_c('CreateCalendarTaskDialog',{attrs:{"open-dialog":_vm.taskDialog,"newTask":_vm.newTask,"action":"create"},on:{"close-dialog":function($event){_vm.taskDialog=false},"create-record":function($event){return _vm.createTask($event)}}}),_c('v-toolbar',{attrs:{"elevation":"0","dense":"","extended":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dateMenuVisible),expression:"dateMenuVisible"}]},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('calendar-page.goto-date'),"readonly":""},model:{value:(_vm.goToSelectedDate),callback:function ($$v) {_vm.goToSelectedDate=$$v},expression:"goToSelectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.goToDate(_vm.goToSelectedDate)}},model:{value:(_vm.goToSelectedDate),callback:function ($$v) {_vm.goToSelectedDate=$$v},expression:"goToSelectedDate"}})],1)],1)]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dateMenuVisible = !_vm.dateMenuVisible}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-calendar")])],1),_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewMenuVisible),expression:"viewMenuVisible"}]},[_c('v-select',{attrs:{"flat":"","item-text":"viewName","item-value":"viewAPIName","items":_vm.enabledViews,"dense":"","light":""},on:{"change":_vm.changeView},model:{value:(_vm.calendarOptions.initialView),callback:function ($$v) {_vm.$set(_vm.calendarOptions, "initialView", $$v)},expression:"calendarOptions.initialView"}})],1)]),_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"light":""},on:{"click":function($event){_vm.viewMenuVisible = !_vm.viewMenuVisible}}},[_vm._v("mdi-view-week")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.navigate('back')}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.navigate('today')}}},[_vm._v(" "+_vm._s(_vm.$t('calendar-page.today'))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.navigate('forward')}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":"/createorder"}},on),[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-clipboard-list-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('calendar-page.create-order')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.addTask}},on),[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-checkbox-marked-circle-plus-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('calendar-page.create-task')))])]),_c('v-switch',{attrs:{"inset":"","hint":_vm.$t('calendar-page.show-tasks-hint'),"hide-details":"auto","dense":"","light":""},on:{"change":_vm.enableTasks},model:{value:(_vm.showTasks),callback:function ($$v) {_vm.showTasks=$$v},expression:"showTasks"}})],1),(!_vm.loading)?_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }