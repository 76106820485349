<template>
  <Loader v-if="loading" type="calendar"></Loader>
  <v-container fluid v-else-if="!loading">
    <CreateCalendarTaskDialog :open-dialog="taskDialog" v-on:close-dialog="taskDialog=false" :newTask="newTask"
                              v-on:create-record="createTask($event)" action="create"></CreateCalendarTaskDialog>
    <v-toolbar elevation="0" dense :extended="$vuetify.breakpoint.smAndDown">

      <template v-slot:extension>
        <v-expand-x-transition>
          <div v-show="dateMenuVisible">
            <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition"
                    offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="goToSelectedDate" :label="$t('calendar-page.goto-date')" readonly
                              v-bind="attrs" v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-on:input="goToDate(goToSelectedDate)" v-model="goToSelectedDate"></v-date-picker>
            </v-menu>
          </div>
        </v-expand-x-transition>

        <v-btn text v-on:click="dateMenuVisible = !dateMenuVisible">
          <v-icon dark>mdi-calendar</v-icon>
        </v-btn>


        <v-expand-x-transition>
          <div v-show="viewMenuVisible">
            <v-select flat item-text="viewName" v-model="calendarOptions.initialView" item-value="viewAPIName"
                      v-on:change="changeView" :items="enabledViews" dense light>
            </v-select>
          </div>
        </v-expand-x-transition>
        <v-btn text>
          <v-icon v-on:click="viewMenuVisible = !viewMenuVisible" light>mdi-view-week</v-icon>
        </v-btn>
      </template>

      <v-btn text v-on:click="navigate('back')">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn text v-on:click="navigate('today')">
        {{$t('calendar-page.today')}}
      </v-btn>
      <v-btn text v-on:click="navigate('forward')">
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/createorder" v-on="on">
            <v-icon light>mdi-clipboard-list-outline</v-icon>
          </v-btn>
        </template>
        <span>{{$t('calendar-page.create-order')}}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on:click="addTask" v-on="on">
            <v-icon light>mdi-checkbox-marked-circle-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{$t('calendar-page.create-task')}}</span>
      </v-tooltip>

      <v-switch v-on:change="enableTasks" v-model="showTasks" inset :hint="$t('calendar-page.show-tasks-hint')"
                hide-details="auto" dense light></v-switch>

    </v-toolbar>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" v-if="!loading"/>
  </v-container>
</template>
<script>
  import '@fullcalendar/core/vdom'
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import list from '@fullcalendar/list'
  import interactionPlugin from '@fullcalendar/interaction'
  import CreateCalendarTaskDialog from "../tasks/CreateCalendarTaskDialog";
  import Loader from "../../components/Loaders/Loader";
  import {AXIOS} from "../../../http-commons";
  import {mapState} from "pinia";
  import {useOrders} from "../../stores/ordersStore";
  import {useTasks} from "../../stores/tasksStore";
  import {useUser} from "../../stores/userStore";

  const ordersStore = useOrders()
  const tasksStore = useTasks()
  const userStore = useUser()

  let moment = require('moment');

  export default {
    components: {FullCalendar, CreateCalendarTaskDialog, Loader},
    data() {
      return {
        dateMenuVisible: false,
        viewMenuVisible: false,
        dateMenu: false,
        goToSelectedDate: '',
        newTask: [],
        startDate: '',
        dueTime: '',
        taskDialog: false,
        showTasks: false,
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline'
        },
        enabledViews: [
          {
            "viewName": this.$t('calendar-views.month'),
            "viewAPIName": 'dayGridMonth',
          },
          {
            "viewName": this.$t('calendar-views.day'),
            "viewAPIName": 'dayGridDay',
          },
          {
            "viewName": this.$t('calendar-views.week-day'),
            "viewAPIName": 'dayGridWeek',
          },
          {
            "viewName": this.$t('calendar-views.week-time'),
            "viewAPIName": 'timeGridWeek',
          },
          {
            "viewName": this.$t('calendar-views.day-time'),
            "viewAPIName": 'timeGridDay',
          },
          {
            "viewName": this.$t('calendar-views.list-week'),
            "viewAPIName": 'listWeek',
          },
          {
            "viewName": this.$t('calendar-views.list-day'),
            "viewAPIName": 'listDay',
          },
        ],
        calendarOptions: {
          weekends: false,
          slotEventOverlap: false,
          allDaySlot: false,
          plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, list],
          initialView: 'dayGridMonth',
          height: 'auto',
          locale: 'en-gb',
          selectable: true,
          nowIndicator: true,
          dayMaxEventRows: 4,
          eventMaxStack: 4,
          slotMinTime: '05:00',
          slotMaxTime: '23:00',
          slotLabelInterval: '02:00',
          firstDay: 1,
          showNonCurrentDates: false,
          slotDuration: '00:30',
          slotLabelFormat: {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false
          },
          businessHours: {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '09:00',
            endTime: '18:00',
          },
          headerToolbar: {
            center: 'title',
            start: '',
            end: ''
          },
          eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          },
          navLinks: true,
          events: [],
        }
      }
    },
    methods: {
      loadUserSettings() {
        let opt = this.calendarOptions;
        let us = this.userSettings;
        opt.firstDay = us.firstDay
        opt.initialView = us.calendarInitialView
        opt.locale = us.calendarLocale
        opt.businessHours.startTime = us.busHoursStart + ':00'
        opt.businessHours.endTime = us.busHoursEnd + ':00'
        opt.slotMinTime = us.slotMinTime + ':00'
        opt.slotMaxTime = us.slotMaxTime + ':00'
        opt.businessHours.daysOfWeek = us.daysOfWeek
        opt.slotDuration = us.slotDuration
        opt.slotLabelInterval = us.slotLabelInterval
        opt.weekends = us.showWeekends
      },
      async updateNeededCheck() {
        if (this.orders.length) {
          this.populateCalOrders()
        }
        if (!this.orders.length) {
          let response = await ordersStore.getOrdersAsync()
          this.populateCalOrders()
        }
        if (!this.tasks.length) {
          tasksStore.populateTasks()
        }
        this.loadUserSettings()
      },
      transition() {
        this.dateMenuVisible = !this.dateMenuVisible;
        this.viewMenuVisible = !this.viewMenuVisible
      },
      goToDate(date) {
        this.dateMenu = false;
        this.fullCalendar = this.$refs.fullCalendar.getApi()
        this.fullCalendar.gotoDate(date);
      },
      enableTasks() {
        if (this.showTasks) {
          this.populateTasks()
        } else {
          this.calendarOptions.events = this.orders
          this.populateCalOrders()
        }
      },
      navigate(direction) {
        let fullCalendar = this.$refs.fullCalendar.getApi()
        if (direction === 'forward') {
          fullCalendar.next();
        } else if (direction === 'back') {
          fullCalendar.prev();
        } else {
          fullCalendar.today();
        }
      },
      addTask(task) {
        this.taskDialog = true;
        let fullCalendar = this.$refs.fullCalendar.getApi()
        fullCalendar.addEvent({
          title: task.taskName,
          start: task.startTime,
          end: task.dueTime,
          backgroundColor: '#FF7043',
          url: 'task/' + task.taskId,
          editable: true
        });
      },
      createTask(task) {
        let action = 'create';
        let objectType = 'Task'
        let format = "yyyy-MM-DDTHH:mm:ss";
        let startTime = moment(task.startTime).format(format);
        let dueTime = moment(task.dueTime).format(format);
        let params = new URLSearchParams();
        params.append('taskName', task.taskName);
        params.append('description', task.description);
        params.append('dueTime', dueTime);
        params.append('startTime', startTime);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createTask/`, params)
          .then(response => {
            if (this.showTasks) {
              this.addTask(response.data);
            }
            tasksStore.addTask(response.data)
            this.taskDialog = false;
            this.userNotification(response, action, objectType);
          })
      },
      changeView() {
        let fullCalendar = this.$refs.fullCalendar.getApi();
        fullCalendar.changeView(this.calendarOptions.initialView);
      },
      populateTasks() {
        let tasks = this.tasks;
        let events = this.calendarOptions.events;
        let backgroundColor;
        let borderColor;
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].taskActive) {
            backgroundColor = '#F57C00';
            borderColor = '#EF6C00';
          } else {
            backgroundColor = '#CFD8DC';
            borderColor = '#90A4AE';
          }
          events.push({
            title: tasks[i].taskName.substr(0, 35),
            start: new Date(tasks[i].startTime),
            end: new Date(tasks[i].dueTime),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            url: 'task/' + tasks[i].taskId
          })
        }
        this.calendarOptions.events = events;
      },
      populateCalOrders() {
        let orders = this.orders;
        const events = [];
        for (let i = 0; i < orders.length; i++) {
          events.push({
            title: '[' + orders[i].agency.agencyName + '] ' + orders[i].description.substr(0, 35),
            start: new Date(orders[i].orderDate),
            end: new Date(orders[i].finishTime),
            backgroundColor: orders[i].status.statusColor,
            url: 'order/' + orders[i].orderId
          })
        }
        this.calendarOptions.events = events;
      },
    },
    computed: {
      ...mapState(useOrders, ['orders', 'loading', 'calendarOptions.events']),
      ...mapState(useTasks, ['tasks']),
      ...mapState(useUser, ['userSettings']),
    },
    created() {
      this.updateNeededCheck()
    },
  }
</script>
